import React from 'react';

const IconDiscord = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="-1.5 -6 40 40"
    fill="none"
    stroke="currentColor"
    strokeWidth="3"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-github">
    <title>Discord</title>
    <path d="M 31.7422 2.832 C 29.3516 1.7266 26.7891 0.9141 24.1094 0.4453 C 24.0625 0.4375 24.0117 0.4609 23.9883 0.5039 C 23.6602 1.0938 23.293 1.8633 23.0391 2.4688 C 20.1563 2.0352 17.2891 2.0352 14.4688 2.4688 C 14.2109 1.8516 13.832 1.0938 13.5039 0.5039 C 13.4766 0.4609 13.4297 0.4375 13.3789 0.4453 C 10.7031 0.9102 8.1406 1.7227 5.75 2.832 C 5.7305 2.8398 5.7109 2.8555 5.6992 2.875 C 0.8398 10.1875 -0.4922 17.3203 0.1602 24.3672 C 0.1641 24.3984 0.1836 24.4336 0.2109 24.4531 C 3.418 26.8242 6.5234 28.2656 9.5703 29.2188 C 9.6211 29.2344 9.6719 29.2188 9.7031 29.1758 C 10.4219 28.1836 11.0664 27.1406 11.6172 26.0391 C 11.6484 25.9727 11.6172 25.8984 11.5508 25.8711 C 10.5313 25.4844 9.5625 25.0078 8.6289 24.4688 C 8.5547 24.4258 8.5469 24.3203 8.6172 24.2695 C 8.8125 24.1211 9.0078 23.9648 9.1953 23.8086 C 9.2305 23.7813 9.2773 23.7734 9.3164 23.793 C 15.4531 26.6133 22.0938 26.6133 28.1563 23.793 C 28.1992 23.7734 28.2461 23.7813 28.2813 23.8086 C 28.4688 23.9648 28.6641 24.1211 28.8633 24.2695 C 28.9297 24.3203 28.9258 24.4258 28.8516 24.4688 C 27.918 25.0195 26.9492 25.4844 25.9258 25.8711 C 25.8594 25.8984 25.832 25.9727 25.8633 26.0391 C 26.4258 27.1367 27.0703 28.1836 27.7773 29.1758 C 27.8047 29.2188 27.8594 29.2344 27.9063 29.2188 C 30.9727 28.2656 34.0781 26.8242 37.2813 24.4531 C 37.3125 24.4336 37.3281 24.4023 37.332 24.3672 C 38.1133 16.2227 36.0234 9.1484 31.7891 2.875 C 31.7773 2.8555 31.7617 2.8398 31.7422 2.832 Z M 12.5352 20.0742 C 10.6875 20.0742 9.1641 18.3672 9.1641 16.2695 C 9.1641 14.1719 10.6563 12.4648 12.5352 12.4648 C 14.4258 12.4648 15.9336 14.1875 15.9023 16.2695 C 15.9023 18.3672 14.4102 20.0742 12.5352 20.0742 Z M 24.9883 20.0742 C 23.1445 20.0742 21.6211 18.3672 21.6211 16.2695 C 21.6211 14.1719 23.1133 12.4648 24.9883 12.4648 C 26.8828 12.4648 28.3867 14.1875 28.3594 16.2695 C 28.3594 18.3672 26.8828 20.0742 24.9883 20.0742 Z M 24.9883 20.0742"></path>
  </svg>
);

export default IconDiscord;
